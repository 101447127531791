<script setup>
import { defineProps } from 'vue'
import { getSettingValueInPropsComputed } from '@/modules/sites-section/site-store-section/pages/composables/useSection'
import MarkdownItVue from 'markdown-it-vue'
import BaseSection from './BaseSection.vue'
// import 'markdown-it-vue/dist/markdown-it-vue.css'

const props = defineProps({
  section: {
    type: Object,
    required: true,
  },
})

const md = getSettingValueInPropsComputed(props, 'md')
</script>

<template>
  <base-section
    :section="section"
  >
    <div class="a-md-section">
      <div class="container">
        <markdown-it-vue
          class="a-md-section__md"
          :content="md"
        />
      </div>
    </div>
  </base-section>
</template>

<style lang="scss">
.a-md-section{
  padding: 20px;
  background: #fff;
  img{
    max-width: 100%;
  }
  &__md{

  }
}
</style>
